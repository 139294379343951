import React, { Fragment } from 'react';
// import { useForm } from 'react-hook-form';
import Link from '../components/Link';
import { useAuth } from '../constants/use-auth.js';

export default function ChangePassword() {
  const auth = useAuth();
  // const { handleSubmit, register, errors } = useForm();
  // const onSubmit = ({ oldpassword, newpassword1, newpassword2 }) => {
  //   // auth.signin(email, password);
  // };
  return <Fragment>
    <div className="container">
      <h1>Mon profil</h1>
      { auth.user ? <>
        <p>Connecté en tant que : <em>{ auth.user.fullname }</em></p>
        <p><button onClick={() => auth.signout()} className="btn btn-primary btn-logout">Se déconnecter</button></p>
        {/* <h2>Changement de mot de passe</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="email">
            <span>Ancien mot de passe</span>
            <input
              name="password"
              type="password"
              ref={register({
                required: "Obligatoire",
              })}
            />
          </label>
          {errors.email && (<p className="error">{errors.email.message}</p>)}

          <button type="submit" className="btn btn-primary">Se connecter</button>
        </form> */}
      </> : <>
        <p>Erreur !<Link to="/" className="btn btn-primary">Retour à l'accueil</Link></p>
      </>}
    </div>
  </Fragment>
}
